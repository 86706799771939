.widget-container-lw {
    position: fixed;
    bottom: 20px;
    right:20px;
    width: 500px;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.06), 0px 6px 10px 0px rgba(0,0,0,0.1), 0px 1px 18px 0px rgba(0,0,0,0.06);
    z-index: 1000;
}

.widget-container-placeholder-lw {
    position: fixed;
    bottom: 20px;
    right:20px;
    width: 500px;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.06), 0px 6px 10px 0px rgba(0,0,0,0.1), 0px 1px 18px 0px rgba(0,0,0,0.06);
    z-index: 1000;
}