.product-card-lw {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 1em;

    &__description--short {
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
    }


    ul {
        margin-left: 2em;
    }
}